<template>
  <div class="flow-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-select
            v-model="searchModel.companyGuid"
            placeholder="所属公司"
            clearable>
            <el-option
              v-for="company in companys"
              :key="company.companyGuid"
              :label="company.companyName"
              :value="company.companyGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchModel.projectGuid" clearable placeholder="所属项目">
            <el-option
              v-for="project in projects"
              :key="project.projectGuid"
              :label="project.projectName"
              :value="project.projectGuid"
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      header-cell-class-name="bg-info text-light"
      border
      :data="flowList">
      <el-table-column width="50" header-align="center" align="center" label="序号" type="index"></el-table-column>
      <el-table-column prop="flowName" header-align="center" align="center" label="流程名称"></el-table-column>
      <el-table-column prop="stepName" header-align="center" align="center" label="步骤名称"></el-table-column>
      <el-table-column prop="identityName" width="100" header-align="center" align="center" label="身份名称"></el-table-column>
      <el-table-column prop="projectName" width="250" header-align="center" align="center" label="项目名称"></el-table-column>
      <el-table-column prop="staffName" width="80" header-align="center" align="center" label="创建者"></el-table-column>
      <el-table-column prop="sortId" width="50" header-align="center" align="center" label="排序"></el-table-column>
      <el-table-column prop="createTime" :formatter="timeFormatter" width="160" header-align="center" align="center" label="创建时间"></el-table-column>
      <el-table-column width="90" header-align="center" align="center" label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      size="md"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <flow-form
        ref="flowForm"
        :flowModel="flowModel">
      </flow-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import FlowForm from '@/views/FlowManage/FlowForm'
import FlowModel from '@/model/FlowModel'
import flowService from '@/services/flowService'
import PageOperation from '@/mixins/PageOperation'
import GetProject from '@/mixins/GetProject'

export default {
  name: 'FlowIndex',
  mixins: [
    GetProject,
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar,
    FlowForm
  },
  data () {
    return {
      flowList: [],
      flowModel: new FlowModel(),
      formDialogTitle: '',
      searchModel: {
        companyGuid: '',
        projectGuid: ''
      }
    }
  },
  watch: {
    companyGuid (newVal) {
      this.$set(this.searchModel, 'projectGuid', '')
      this.projects = []
      newVal && this._getProjects(newVal)
    }
  },
  computed: {
    companyGuid () {
      return this.searchModel.companyGuid
    }
  },
  methods: {
    handleSearchClick () {
      this._getFlowList()
    },
    handleAddClick () {
      this.formDialogTitle = '添加流程信息'
      this.flowModel = new FlowModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑流程信息'
      this.flowModel = new FlowModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _flowModel = new FlowModel(row)
          flowService.delete(_flowModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getFlowList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.flowForm.validate()(valid => {
        if (valid) {
          if (!this.flowModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getFlowList () {
      flowService.list({}, this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.flowList = res.data.data.map(item => {
              item.nextUsers = item.nextUsers.split(',')
              return item
            })
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      this.flowModel.nextUsers = this.flowModel.nextUsers.join(',')
      flowService.add(this.flowModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getFlowList()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      this.flowModel.nextUsers = this.flowModel.nextUsers.join(',')
      flowService.edit(this.flowModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getFlowList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getFlowList()
  }
}
</script>
