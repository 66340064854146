import BaseService from '@/services/BaseService'

let resourceName = 'flow'

class FlowService extends BaseService {
}

const flowService = new FlowService(resourceName)

export default flowService
