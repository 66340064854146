import BaseService from '@/services/BaseService'

let resourceName = 'identity'

class IdentityService extends BaseService {
}

const identityService = new IdentityService(resourceName)

export default identityService
